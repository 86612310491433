import React, { useState, useEffect } from 'react';

import { getQueryString } from 'utils/url';
// styles
import { StyledVRMode } from './StyledVRMode';

// images
import { ReactComponent as ZoomIcon } from './images/zoomIcon.svg';
import { ReactComponent as RotateIcon } from './images/rotateIcon.svg';
import { ReactComponent as SmoothMoveIcon } from './images/smoothMoveIcon.svg';
import { ReactComponent as DefaultIcon } from './images/defaultIcon.svg';
import { ReactComponent as QrFrameIcon } from './images/qrFrame.svg';

// i18n
import { useTranslation } from 'react-i18next';

//plugin
import QRCode from 'qrcode.react';
import Cookies from 'js-cookie';

//env
import pageUrl from 'constants/pageUrl';


const ForARQRcode = (props) => {

    const { t } = useTranslation();
    return (
        <div className='qrcode_container'>
            <h1>
                {t('QRcode.forMobileOrTablet')}
            </h1>
            <span>
                {t('QRcode.supportDevice')}{': iPhone 6S+ & iPad 5+ on iOS 12+ and Android 8.0+ with ARCore 1.9 support.'}
            </span>
            <div className="qrcode_box">
                <QRCode
                    className="qrcode"
                    id="bill_qr_code_url"
                    value={props.defaultUrl}
                    size={130}
                    fgColor="#000000"
                />
                <div className="qrcode_frame">
                    <QrFrameIcon />
                </div>
            </div>
            <h1>
                {t('QRcode.scanQRCode')}
            </h1>
            <span>
                {t('QRcode.experienceAR')}
            </span>
        </div>
    );
};




export default function VRMode(props) {

    const { t } = useTranslation();

    // 處理QRcode
    const [defaultUrl, setDefaultUrl] = useState('');
    const uid = getQueryString('uid');

    useEffect(() => {
        setDefaultUrl(`${pageUrl.AR_VIEWER}?uid=${uid}&modelIndex=${props.modelIndex}&openAr=true`);
    }, []);

    const closeTutorialMode = () => {
        props.forVRdispatch({ type: 'closeTutorialMode' });
    };

    return (
        <StyledVRMode>
            {
                props.forVRstate.tutorialMode != null &&
                <>
                    <div className="tutorialMode">
                        {/* 開啟手勢輔助 */}
                        {
                            props.forVRstate.tutorialMode === 'tutorial' &&
                            <div className="gestureContainer">
                                <div className="gesture_item">
                                    <div className="gesture_item_title">{t('Tutorial.zoom')}</div>
                                    <ZoomIcon />
                                </div>
                                <div className="gesture_item">
                                    <div className="gesture_item_title">{t('Tutorial.rotate')}</div>
                                    <RotateIcon />
                                </div>
                                <div className="gesture_item">
                                    <div className="gesture_item_title">{t('Tutorial.smoothMove')}</div>
                                    <SmoothMoveIcon />
                                </div>
                                <div className="gesture_item">
                                    <div className="gesture_item_title">{t('Tutorial.default')}</div>
                                    <DefaultIcon />
                                </div>
                            </div>
                        }
                        {/* 開啟QR掃碼 */}
                        {
                            props.isQrcode && props.forVRstate.tutorialMode === 'ARactive' &&
                            <ForARQRcode defaultUrl={defaultUrl} modelIndex={props.modelIndex} />
                        }
                        <div className="closeTutorialMode" onClick={closeTutorialMode}>
                            {
                                props.forVRstate.tutorialMode === 'tutorial' ? 'Close Tutorial' : 'Close QR Code'
                            }
                        </div>
                    </div>
                </>
            }
        </StyledVRMode>
    );
}
