import React, { useState, useEffect } from 'react';

import { getQueryString } from 'utils/url';

//env
import pageUrl from 'constants/pageUrl';

// styled components
import { StyledQRcode } from './StyledQRcode';

//react icon
import { AiOutlineClose } from 'react-icons/ai';

//plugin
import QRCode from 'qrcode.react';
import Cookies from 'js-cookie';

// i18n
import { useTranslation } from 'react-i18next';

const QRcode = ({ setQrcode, modelIndex })=>{

    const { t } = useTranslation();

    const [defaultUrl, setDefaultUrl] = useState('');

    useEffect(()=>{
        const queryString = window.location.search;
        const params = new URLSearchParams(queryString);
        params.set('modelIndex', modelIndex);
        params.set('openAr', 'true');

        setDefaultUrl(`${pageUrl.AR_VIEWER}?${params.toString()}`);
    }, [modelIndex]);

    const closeQrCode = ()=>{
        setQrcode(false);
    };

    return (
        <StyledQRcode>
            <div className='closeIcon' onClick={closeQrCode}>
                <AiOutlineClose />
            </div>
            <div className='qrcode_container'>
                <h1>
                    {t('QRcode.forMobileOrTablet')}
                </h1>
                <span>
                    {t('QRcode.supportDevice')}{': iPhone 6S+ & iPad 5+ on iOS 12+ and Android 8.0+ with ARCore 1.9 support.'}
                </span>
                <QRCode
                    className="qrcode"
                    id="bill_qr_code_url"
                    value={defaultUrl}
                    size={180}
                    fgColor="#000000"
                />
                <h1>
                    {t('QRcode.scanQRCode')}
                </h1>
                <span>
                    {t('QRcode.experienceAR')}
                </span>
            </div>
        </StyledQRcode>
    );
};

export default QRcode;
